
import { mapGetters } from 'vuex'

export default {
  components: {

  },

  // async asyncData ({ params, $axios, app }) {
  //   const banners = await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/banners`)
  //     .catch((error) => {
  //       console.log(error)
  //     })

  //   return {
  //     banners: banners.data
  //   }
  // },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      currency: 'currency',
      country: 'country'
    })
  },

  head () {
    return {
      title: this.$t('pages.calculators.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.calculators.description')
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: ''
        }
      ]
    }
  }
}
