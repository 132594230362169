
import { mapGetters } from 'vuex'

import Cartable from '@/components/Cartable'
import CartFooter from '@/components/CartFooter'

export default {
  components: {
    Cartable,
    CartFooter
  },

  asyncData ({ from, store }) {
    if (from) {
      store.dispatch('routing/setFrom', (from.fullPath || null))
    }
  },

  data () {
    return {
      height: {
        header: 0,
        item: 0,
        footer: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      currency: 'currency',
      from: 'routing/from',
      logo: 'logo'
    }),

    totalCartDeliveryPrice () {
      return 0
    },

    totalCartPrice () {
      return this.cart.items.reduce((total, item) => total + item.total, 0)
    }
  },

  layout: 'cart',

  head () {
    return {
      title: this.$t('pages.cart.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.cart.description')
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('pages.cart.title')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('pages.cart.description')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        // },
        // {
        //   hid: 'keywords',
        //   name: 'keywords',
        //   content: ''
        }
      ]
    }
  }
}
