
export default {
  middleware: ['auth', 'guest'],
  auth: 'guest',

  mounted () {
    if (this.$route.query.token) {
      this.$auth.setStrategy('laravelJWT')
      this.$auth.strategy.refreshToken.set(true)
      this.$auth.strategy.token.set('Bearer ' + this.$route.query.token)

      this.$auth.fetchUser()
        .then((res) => {
          this.$router.replace(this.localePath({ name: 'index' }))
        })
        .catch((e) => {
          this.$auth.logout()
        })
    } else if (this.$route.query.error === 'unregistered') {
      this.$router.replace(this.localePath({ name: 'register', query: { error: 'unregistered' } }))
    } else if (this.$route.query.error === 'failed') {
      this.$router.replace(this.localePath({ name: 'login', query: { error: 'failed' } }))
    }
  }
}
